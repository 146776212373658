var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page-layout',{attrs:{"title":_vm.$t('hr.travel.request_travel'),"icon":"tw-hr-cost"},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"observer",attrs:{"slim":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"color":"teal","hide-details":"","label":_vm.$t('hr.travel.new_leave_request')},model:{value:(_vm.newLeaveRequest),callback:function ($$v) {_vm.newLeaveRequest=$$v},expression:"newLeaveRequest"}})],1),(_vm.newLeaveRequest)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('time.calendar.leave_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('leave-type-picker',{attrs:{"return-object":"","loading":_vm.loading,"error-messages":errors,"filter-items":function (item) { return item.applicable.hasTravel; }},model:{value:(_vm.leaveType),callback:function ($$v) {_vm.leaveType=$$v},expression:"leaveType"}})]}}],null,false,3658871425)})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.leave_request'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('leave-request-picker',{attrs:{"return-object":"","error-messages":errors},model:{value:(_vm.leaveRequest),callback:function ($$v) {_vm.leaveRequest=$$v},expression:"leaveRequest"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.begin_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"max":_vm.travel.endDate || _vm.maxEndDate,"min":_vm.minBeginDate,"label":_vm.$t('hr.travel.begin_date'),"error-messages":errors},model:{value:(_vm.travel.beginDate),callback:function ($$v) {_vm.$set(_vm.travel, "beginDate", $$v)},expression:"travel.beginDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.end_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"min":_vm.travel.beginDate || _vm.minBeginDate,"max":_vm.maxEndDate,"label":_vm.$t('hr.travel.end_date'),"error-messages":errors},model:{value:(_vm.travel.endDate),callback:function ($$v) {_vm.$set(_vm.travel, "endDate", $$v)},expression:"travel.endDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.start_work_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"min":_vm.travel.endDate || _vm.maxEndDate,"label":_vm.$t('hr.travel.start_work_date'),"error-messages":errors},model:{value:(_vm.travel.startWorkDate),callback:function ($$v) {_vm.$set(_vm.travel, "startWorkDate", $$v)},expression:"travel.startWorkDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"color":"teal","label":_vm.$t('hr.travel.is_domestic')},model:{value:(_vm.travel.isDomestic),callback:function ($$v) {_vm.$set(_vm.travel, "isDomestic", $$v)},expression:"travel.isDomestic"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('global.address'),"rules":"required|max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('address-picker',{attrs:{"error-messages":errors},on:{"change":_vm.onAddressChange},model:{value:(_vm.travel.address),callback:function ($$v) {_vm.$set(_vm.travel, "address", $$v)},expression:"travel.address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.country'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.travel.country'),"counter":255,"color":"tertiary","error-messages":errors},model:{value:(_vm.travel.country),callback:function ($$v) {_vm.$set(_vm.travel, "country", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"travel.country"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.city'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.travel.city'),"counter":255,"color":"tertiary","error-messages":errors},model:{value:(_vm.travel.city),callback:function ($$v) {_vm.$set(_vm.travel, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"travel.city"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.region'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.travel.region'),"counter":255,"color":"tertiary","error-messages":errors},model:{value:(_vm.travel.region),callback:function ($$v) {_vm.$set(_vm.travel, "region", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"travel.region"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.description'),"rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"1","label":_vm.$t('hr.travel.description'),"counter":500,"color":"tertiary","error-messages":errors},model:{value:(_vm.travel.travelDescription),callback:function ($$v) {_vm.$set(_vm.travel, "travelDescription", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"travel.travelDescription"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.travel.reason'),"rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"1","label":_vm.$t('hr.travel.reason'),"counter":500,"color":"tertiary","error-messages":errors},model:{value:(_vm.travel.reason),callback:function ($$v) {_vm.$set(_vm.travel, "reason", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"travel.reason"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('project-picker',{model:{value:(_vm.travel.project),callback:function ($$v) {_vm.$set(_vm.travel, "project", $$v)},expression:"travel.project"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('travel-details',{ref:"travelDetails",attrs:{"min-date":_vm.travel.beginDate || _vm.minBeginDate,"max-date":_vm.travel.endDate || _vm.maxEndDate},model:{value:(_vm.travel.travelDetails),callback:function ($$v) {_vm.$set(_vm.travel, "travelDetails", $$v)},expression:"travel.travelDetails"}})],1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{attrs:{"create":"","disabled":_vm.$helpers.get(_vm.$refs, 'travelDetails.disableCreateButton', false)},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.validateAndSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }